/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Default layout
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-default {
  display: flex;
  flex-direction: column;

  @include mq($until: "medium") {
    --layout-default-pb: #{rem(30px)};
  }

  @include mq($from: "medium", $until: "large") {
    --layout-default-pb: #{rem(60px)};
  }

  @include mq($from: "medium") {
    --layout-default-pb: #{rem(100px)};
  }
}

.o-default--nopb {
  --layout-default-pb: 0;
}

  .o-default__header {}

  .o-default__main {
    flex: 1 1 auto;
    padding-top: var(--header-height);
    padding-bottom: var(--layout-default-pb);

    .o-default--center & {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .o-default__footer {}
