/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Error
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-error {
  --wrapper-width: #{rem(560px)};

  text-align: center;
}

  .c-error__gimmick {}

  .c-error__title {
    margin-bottom: 0;

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }

  .c-error__desc {
    margin-top: rem(20px);
    margin-bottom: 0;

    @include mq($from: "medium") {
      @include typo-medium;
    }
  }

  .c-error__button {
    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }
