/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-icon {
  display: inline-block;
  vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
}

  .c-icon__svg {
    display: block;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
