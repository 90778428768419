/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Tool
 * Description: Text common styles.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Mixins
*******************************************************************************/

/**
 * Title level 1
*******************************************************************************/

$h1-size-small: 30px;
$h1-size-medium: 36px;
$h1-size-large: 46px;

@mixin typo-h1($at: "") {
  font-family: $font-serif;
  font-weight: $FONT_REGULAR;
  line-height: math.div(41px, $h1-size-small);

  @if ($at == "") {
    @include mq($until: "medium") {
      font-size: $h1-size-small;
    }

    @include mq($from: "medium", $until: "large") {
      font-size: $h1-size-medium;
    }

    @include mq($from: "large") {
      font-size: $h1-size-large;
    }
  } @else if ($at == "small") {
    font-size: $h1-size-small;
  } @else if ($at == "medium") {
    font-size: $h1-size-medium;
  } @else if ($at == "large") {
    font-size: $h1-size-large;
  } @else {
    @error "No specific styles for typo-h1 at \"#{$at}\".";
  }
}

/**
 * Title level 2
*******************************************************************************/

$h2-size-small: 26px;
$h2-size-medium: 30px;
$h2-size-large: 36px;

@mixin typo-h2($at: "") {
  font-family: $font-serif;
  font-weight: $FONT_REGULAR;
  line-height: math.div(32px, $h2-size-small);

  @if ($at == "") {
    @include mq($until: "medium") {
      font-size: $h2-size-small;
    }

    @include mq($from: "medium", $until: "large") {
      font-size: $h2-size-medium;
    }

    @include mq($from: "large") {
      font-size: $h2-size-large;
    }
  } @else if ($at == "small") {
    font-size: $h2-size-small;
  } @else if ($at == "medium") {
    font-size: $h2-size-medium;
  } @else if ($at == "large") {
    font-size: $h2-size-large;
  } @else {
    @error "No specific styles for typo-h2 at \"#{$at}\".";
  }
}

/**
 * Title level 3
*******************************************************************************/

$h3-size-small: 24px;
$h3-size-medium: 26px;
$h3-size-large: 30px;

@mixin typo-h3($at: "") {
  font-family: $font-serif;
  font-weight: $FONT_REGULAR;
  line-height: math.div(32px, $h3-size-small);

  @if ($at == "") {
    @include mq($until: "medium") {
      font-size: $h3-size-small;
    }

    @include mq($from: "medium", $until: "large") {
      font-size: $h3-size-medium;
    }

    @include mq($from: "large") {
      font-size: $h3-size-large;
    }
  } @else if ($at == "small") {
    font-size: $h3-size-small;
  } @else if ($at == "medium") {
    font-size: $h3-size-medium;
  } @else if ($at == "large") {
    font-size: $h3-size-large;
  } @else {
    @error "No specific styles for typo-h3 at \"#{$at}\".";
  }
}

/**
 * Title level 4
*******************************************************************************/

$h4-size-small: 22px;
$h4-size-medium: 24px;
$h4-size-large: 26px;

@mixin typo-h4($at: "") {
  font-family: $font-serif;
  font-weight: $FONT_REGULAR;
  line-height: math.div(32px, $h4-size-small);

  @if ($at == "") {
    @include mq($until: "medium") {
      font-size: $h4-size-small;
    }

    @include mq($from: "medium", $until: "large") {
      font-size: $h4-size-medium;
    }

    @include mq($from: "large") {
      font-size: $h4-size-large;
    }
  } @else if ($at == "small") {
    font-size: $h4-size-small;
  } @else if ($at == "medium") {
    font-size: $h4-size-medium;
  } @else if ($at == "large") {
    font-size: $h4-size-large;
  } @else {
    @error "No specific styles for typo-h4 at \"#{$at}\".";
  }
}

/**
 * Title level 5
*******************************************************************************/

$h5-size-small: 18px;
$h5-size-large: 20px;

@mixin typo-h5($at: "") {
  font-family: $font-serif;
  font-weight: $FONT_REGULAR;
  line-height: math.div(25px, $h5-size-small);

  @if ($at == "") {
    @include mq($until: "large") {
      font-size: $h5-size-small;
    }

    @include mq($from: "large") {
      font-size: $h5-size-large;
    }
  } @else if ($at == "small") {
    font-size: $h5-size-small;
  } @else if ($at == "large") {
    font-size: $h5-size-large;
  } @else {
    @error "No specific styles for typo-h5 at \"#{$at}\".";
  }
}

/**
 * Headline
*******************************************************************************/

$headline-size-small: 12px;
$headline-size-large: 16px;

@mixin typo-headline($at: "") {
  font-weight: $FONT_BLACK;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @if ($at == "") {
    @include mq($until: "large") {
      font-size: $headline-size-small;
    }

    @include mq($from: "large") {
      font-size: $headline-size-large;
    }
  } @else if ($at == "small") {
    font-size: $headline-size-small;
  } @else if ($at == "large") {
    font-size: $headline-size-large;
  } @else {
    @error "No specific styles for typo-headline at \"#{$at}\".";
  }
}

/**
 * Large
*******************************************************************************/

@mixin typo-large($at: "") {
  font-size: rem(22px);

  @if ($at != "") {
    @error "No specific styles for typo-large at \"#{$at}\".";
  }
}

/**
 * Medium
*******************************************************************************/

@mixin typo-medium($at: "") {
  font-size: rem(18px);

  @if ($at != "") {
    @error "No specific styles for typo-medium at \"#{$at}\".";
  }
}

/**
 * Normal
*******************************************************************************/

@mixin typo-normal($at: "") {
  font-size: rem($global-font-size);

  @if ($at != "") {
    @error "No specific styles for typo-normal at \"#{$at}\".";
  }
}

/**
 * Small
*******************************************************************************/

@mixin typo-small($at: "") {
  font-size: rem(14px);

  @if ($at != "") {
    @error "No specific styles for typo-small at \"#{$at}\".";
  }
}

/**
 * Very small
*******************************************************************************/

@mixin typo-very-small($at: "") {
  font-size: rem(14px);

  @if ($at != "") {
    @error "No specific styles for typo-small at \"#{$at}\".";
  }
}
