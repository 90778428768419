/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Setting
 * Description:
 *   The global settings file contains any project-wide variables; things that
 *   need to be made available to the entire codebase.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass:math";

/*******************************************************************************
 * Typography
*******************************************************************************/

$global-font-size: 14px;
$global-line-height-px: 22.19px;
$global-line-height: math.div($global-line-height-px, $global-font-size);

/*******************************************************************************
 * Breakpoints
*******************************************************************************/

//  App's core breakpoints, used for utilities or generic loops.
$global-core-breakpoints: (
  "small": 320px,
  "medium": 720px,
  "large": 960px,
  "xlarge": 1440px,
);

/**
 * Store current core and future breakpoints for sass-mq use.
 * So this map will grow through the app step by step as we storing new
 * breakpoints (because low usage or component's specifics).
 */
$global-mq-breakpoints: map.merge(
  $global-core-breakpoints,
  (
    "header-large": 1200px,
  ),
);

/*******************************************************************************
 * Z-indexes
*******************************************************************************/

$global-z-header: 30;
$global-z-nav: $global-z-header + 1;
$global-z-main: 20;
$global-z-footer: 10;
$global-z-overlay: 40;

/*******************************************************************************
 * UI
*******************************************************************************/

$global-spacing-unit: 20px;

$global-icons: (
  "chevron-down": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.115 8.295l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6 1.41-1.41z" fill="%color%" /></svg>',
  "chevron-left": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 7.41L10.83 12l4.58 4.59L14 18l-6-6 6-6 1.41 1.41z" fill="%color%" /></svg>',
  "chevron-right": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 16.59L12.58 12 8 7.41 9.41 6l6 6-6 6L8 16.59z" fill="%color%" /></svg>',
  "chevron-up": '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.295 15.705l-4.59-4.58-4.59 4.58-1.41-1.41 6-6 6 6-1.41 1.41z" fill="%color%" /></svg>',
);
