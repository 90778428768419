/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Element
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

address {
  font-style: normal;
}

strong {
  font-weight: $FONT_BOLD;
}

h1 {
  @include typo-h1;
}

h2 {
  @include typo-h2;
}

h3 {
  @include typo-h3;
}

h4 {
  @include typo-h4;
}

h5 {
  @include typo-h5;
}

hr {
  position: relative;
  padding-top: calc(var(--hr-size) * 0.5);
  padding-bottom: calc(var(--hr-size) * 0.5);
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  border: 0;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: rem(1px);
    margin-top: rem(-0.5px);
    background: color("cream");
  }

  &::after {
    @include global-k-gimmick(color("black"), "background");

    --gimmick-width: calc(var(--hr-size) * 0.32);

    content: "";
    position: absolute;
    top: calc(50% - (var(--hr-size) * 0.86 * 0.5));
    left: calc(50% - (var(--hr-size) * 0.86 * 0.5));
    width: calc(var(--hr-size) * 0.86);
    height: calc(var(--hr-size) * 0.86);
    background-color: color("white");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    box-shadow: 0 0 0 calc(0.07 * var(--hr-size)) color("cream");
  }

  @include mq($until: "medium") {
    --hr-size: #{rem(60px)};
  }

  @include mq($from: "medium") {
    --hr-size: #{rem(100px)};
  }
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media print {
  a {
    text-decoration: none;
    color: #000 !important;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
