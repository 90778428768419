/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Page
 * Type: Elements
 * Description: Simple page-level setup.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Scroll behavior disabled due to https://bugs.chromium.org/p/chromium/issues/detail?id=1245534&q=scroll-behavior&can=2
**/
html {
  height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  font-family: $font-default;
  font-size: math.div($global-font-size, 16px) * 1em; /* [1] */
  font-feature-settings: "lnum";
  line-height: $global-line-height; /* [1] */
  color: color("foreground");
  background: color("background") none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // scroll-behavior: smooth; /* [4] */
  scroll-padding-top: calc(var(--header-height) + #{rem(20px)});
}

body {
  min-height: 100%;
}

main {
  position: relative;
  z-index: $global-z-main;
}

details > * {
  box-sizing: border-box;
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media print {
  @page {
    margin: 1cm 1.5cm;
  }

  html,
  body {
    height: auto;
  }

  html {
    @include rem-baseline(90%);
  }

  *,
  *::before,
  *::after {
    color: #000 !important;
    background: transparent none !important;
    border-color: #000 !important;
    box-shadow: none !important;
  }
}
