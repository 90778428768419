/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Tools
 * Description:
 *   Global mixins, functions or placeholders used through the entire codebase,
 *   but not specific enough to be defined under it's own scope.
**/

/*******************************************************************************
 * Mixins
*******************************************************************************/

// Customize the scrollbar for webkit browsers
@mixin global-custom-scrollbar() {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(5px);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color("black");
    border-radius: rem(3px);
  }
}

/**
 * Limit number of lines
 * See: https://developer.mozilla.org/fr/docs/Web/CSS/-webkit-line-clamp
**/
@mixin global-line-clamp($number) {
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
  line-clamp: $number;
  overflow: hidden;
}

/**
 * Add "K" gimmick
 * See: https://developer.mozilla.org/fr/docs/Web/CSS/-webkit-line-clamp
**/
@mixin global-k-gimmick($color, $method: "block", $set_cp: true) {
  $gimmick: "<svg viewBox=\"0 0 100 140\" xmlns=\"http://www.w3.org/2000/svg\">\
      <path d=\"M3.27 102.52c-1.29 4-4.73 8-2.58 11.12 1.72 2.23 4.3 5.79 9.04 3.12 4.3-12.91 7.31-26.7 11.61-39.62 6.89 6.24 10.76 14.7 17.22 21.82a296.8 296.8 0 0022.36 24.48c7.32 6.67 13.77 14.24 23.24 16.02 5.16.9 10.37-3.42 13.77-6.67 1.58-1.5 3-4.9 1.29-4.9-10.75.45-20.23-3.56-28.4-10.69-18.5-16.02-32.7-35.16-44.31-56.08.42-4.45 4.3-6.68 6.88-9.35 15.92-12.9 32.26-21.37 49.48-33.4 1.29-.88 2.58-1.33 3.87-.88 1.3.44 1.3 2.67 3 2.67 1.73 0 2.16-1.34 2.16-2.67 0-4 0-7.57-.43-11.57C91.04 2.8 88.8-.73 85.45.13 73.25 3.27 51.03 27.28 32.96 39.3c2.58-12.46 7.31-24.04 11.19-35.62-3.44-3.1-7.89.67-11.19 2.67-2.58 14.26-6.88 27.61-11.62 40.97-1.37-1.43-1.5-3.8-1.38-5.13.2-2.09 3.77-4.1 3.1-5.11-4.61-1.05-11.62 4-12.05 9.8-.43 6.22 1.73 12.02 4.31 17.35l.53 1.1c-3.87 12.02-8.7 25.18-12.58 37.2\" fill=\"%color%\"/>\
    </svg>";

    @if $set_cp {
      --gimmick-width: #{rem(100px)};
    }

    background-image: icon($gimmick, $color);

    @if $method == "block" {
      display: block;
      width: var(--gimmick-width);
      height: calc(1.4 * var(--gimmick-width));
      background-size: 100% 100%;
    } @else if $method == "background" {
      background-size: var(--gimmick-width) calc(1.4 * var(--gimmick-width));
    }
}

/*******************************************************************************
 * Functions
*******************************************************************************/

// Customize the scrollbar for webkit browsers
@function global-spacing($coeff: 1, $base: rem($global-spacing-unit)) {
  @return $coeff * $base;
}
