/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Button
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:color";

$button-inner-space: 8px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--button-padding, 0);
  font-size: rem(14px);
  font-weight: $FONT_BOLD;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  color: var(--button-color, currentColor);
  background: var(--button-bg, transparent);
  border: rem(1px) solid var(--button-border-color, var(--button-bg));
  @include transition(background border color);
}

  .c-button__inner {
    display: flex;
    align-items: center;
    margin: rem(-$button-inner-space * 0.5);
  }

    .c-button__icon,
    .c-button__text {
      margin: rem($button-inner-space * 0.5);
    }

    .c-button__icon {
      flex: 0 0 auto;
      font-size: var(--button-icon-size, #{rem(16px)});
      color: var(--button-icon-color, var(--button-color));
      @include transition(color);

      .c-button--revert & {
        order: 2;
      }

      .c-button--medium & {
        font-size: rem(16px);
      }

      .c-button--big & {
        font-size: rem(18px);
      }
    }

    .c-button__text {
      .c-button--icon & {
        @include hidden-visually;
      }
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-button--medium {
  --button-padding: #{rem(8px 15px)};

  &.c-button--icon {
    --button-padding: #{rem(8px)};
  }
}

.c-button--big {
  --button-padding: #{rem(12px 23px)};

  &.c-button--icon {
    --button-padding: #{rem(14px)};
    --button-icon-size: #{rem(18px)};
  }
}

.c-button--primary {
  &.c-button--light {
    --button-bg: #{color("black")};
    --button-color: #{color("white")};

    &:hover,
    &.focus-visible {
      --button-bg: #{color("primary")};
      --button-color: #{color("black")};
    }
  }

  &.c-button--dark {
    --button-bg: #{color("cream")};
    --button-color: #{color("black")};

    &:hover,
    &.focus-visible {
      --button-bg: #{color("black")};
      --button-color: #{color("cream")};
      --button-border-color: #{color("cream")};
    }
  }
}

.c-button--secondary {
  &.c-button--light {
    --button-bg: transparent;
    --button-color: #{color("black")};
    --button-border-color: #{color("black")};

    &:hover,
    &.focus-visible {
      --button-border-color: #{color("cream")};
    }
  }

  &.c-button--dark {
    --button-bg: #{color("black")};
    --button-color: #{color("cream")};
    --button-border-color: #{color("cream")};

    &:hover,
    &.focus-visible {
      --button-bg: #{color("cream")};
      --button-color: #{color("black")};
    }
  }
}

.c-button--teal {
  --button-bg: #{color("teal")};
  --button-color: #{color("white")};
  // --button-border-color: #{color("black")};

  &:hover,
  &.focus-visible {
    --button-bg: #{color("primary")};
    --button-color: #{color("black")};
  }
}

.c-button--blue {
  --button-bg: #{color("blue")};
  --button-color: #{color("white")};
  // --button-border-color: #{color("black")};

  &:hover,
  &.focus-visible {
    --button-bg: #{color("primary")};
    --button-color: #{color("black")};
  }
}

.c-button--green,
.c-product-button__green .c-button--primary.c-button--light{
  --button-bg: #{color("green")};
  --button-color: #{color("white")};

  &:hover,
  &.focus-visible {
    --button-bg: #{color("black")};
    --button-color: #{color("white")};
  }
}

.c-button--link {
  --button-padding: 0;
  --button-icon-color: #{color("secondary")};

  &:hover,
  &.focus-visible {
    --button-icon-color: currentColor;
  }

  &.c-button--dark {
    --button-color: #{color("white")};

    &:hover,
    &.focus-visible {
      --button-color: #{color("primary")};
    }
  }
}

/*******************************************************************************
 * Disabled
*******************************************************************************/

.c-button[disabled],
.c-button.is-disabled {
  cursor: not-allowed;

  &,
  &.c-button--primary,
  &.c-button--secondary {
    --button-bg: #{color("neutral")};
    --button-color: #6c727f;
    --button-border-color: #{color("neutral")};
  }

  &.c-button--link {
    --button-bg: initial;
    --button-color: #9da3ae;
    --button-border-color: initial;
    --button-icon-color: currentColor;
  }
}

/*******************************************************************************
 * Loading
*******************************************************************************/

@keyframes button-loading {
  0% {
    right: 50%;
    left: 50%;
  }

  50% {
    right: rem(-1px);
    left: rem(-1px);
  }

  100% {
    right: 50%;
    left: 50%;
  }
}

.c-button--withload {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: rem(-1px);
    bottom: rem(-1px);
    left: rem(-1px);
    height: rem(4px);
    background: var(--button-color);
    opacity: 0;
    @include transition(opacity);
  }

  &.is-loading {
    &::before {
      opacity: 0.3;
    }

    &::after {
      opacity: 0.9;
      animation: button-loading 1.5s ease-in-out infinite;
    }
  }
}

.c-button.is-loading {
  cursor: progress;
  opacity: 0.8;
}
