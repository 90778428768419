/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Styles
*******************************************************************************/

@font-face {
  font-display: swap;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: $FONT_REGULAR;
  src: url("#{$font-folder}dm-serif-display-regular.woff2")  format("woff2");
}

@font-face {
  font-display: swap;
	font-family: "Raleway";
  font-style: normal;
  font-weight: 100 900;
	src:
    url("#{$font-folder}raleway-vf.woff2") format("woff2 supports variations"),
    url('#{$font-folder}raleway-vf.woff2') format("woff2-variations");
}

@font-face {
  font-display: swap;
	font-family: "Raleway";
  font-style: italic;
  font-weight: 100 900;
	src:
    url("#{$font-folder}raleway-vf-italic.woff2") format("woff2 supports variations"),
    url('#{$font-folder}raleway-vf-italic.woff2') format("woff2-variations");
}
