/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Setting
**/

/*******************************************************************************
 * @font-face helpers
*******************************************************************************/

$font-folder: "../fonts/";

/*******************************************************************************
 * Families
*******************************************************************************/

$FONT_SYSTEM:
  -apple-system,
  "BlinkMacSystemFont",
  "Helvetica Neue",
  "Segoe UI",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Open Sans",
  sans-serif;
$font-serif: "DM Serif Display", serif;
$font-sans-serif: "Raleway", $FONT_SYSTEM;
$font-default: $font-sans-serif;

/*******************************************************************************
 * Weights
*******************************************************************************/

$FONT_THIN: 100;
$FONT_EXTRALIGHT: 200;
$FONT_LIGHT: 300;
$FONT_REGULAR: 400;
$FONT_MEDIUM: 500;
$FONT_SEMIBOLD: 600;
$FONT_BOLD: 700;
$FONT_EXTRABOLD: 800;
$FONT_BLACK: 900;
